import { PageProps } from "gatsby";
import * as React from "react";
import Contest from "src/routes/Contest";

interface Props extends PageProps {}

const index: React.SFC<Props> = (props) => {
  // @ts-ignore
  return <Contest {...props} data={props.pageContext.contests} />;
};
export default index;
