import * as React from "react";
import Button from "src/components/Button/Button";
import Favorites from "src/components/Favorites";
import PageHeader from "src/components/PageHeader";
import Typography from "src/components/Typography";
import { CONTESTS_FORMATS_NAMES } from "src/consts";
import { Contest as ContestType } from "src/types";
import classes from "./Contest.module.css";
import { RenderDate } from "./RenderDate";

interface Props extends ContestType {}

const Contest: React.SFC<Props> = (props) => {
  return (
    <div>
      <PageHeader text={props.name} />
      <div className={classes.wrapper}>
        <div>
          <div className={classes.imgWrapper}>
            <img src={props.img} />
          </div>
          <div className={classes.textWrapper}>
            <Typography type="text">
              {/* tslint:disable-next-line */}
              <span
                dangerouslySetInnerHTML={{
                  __html: props.description,
                }}
              />
            </Typography>
          </div>
        </div>
        <div>
          <div className={classes.descriptionWrapper}>
            <RenderDate
              dateFinish={props.dateFinish}
              dateStart={props.dateStart}
              isRange={props.isRange}
            />
            <dl>
              <dt>
                <Typography type="smallerText">Стоимость:</Typography>
              </dt>
              <dd>
                <Typography type="boldText">
                  {props.price ? `${props.price} Р` : "Бесплатно"}
                </Typography>
              </dd>
            </dl>
            <dl>
              <dt>
                <Typography type="smallerText">Формат:</Typography>
              </dt>
              <dd>
                <Typography type="boldText">
                  {CONTESTS_FORMATS_NAMES[props.format] || "Любой"}
                </Typography>
              </dd>
            </dl>
          </div>
          {props.externalLink ? (
            <a href={props.externalLink} target="blank">
              <Button>участвовать</Button>
            </a>
          ) : null}
          <div
            style={{
              margin: "24px 0",
            }}
          >
            <Favorites contestId={props._id} value={props.isFavorite} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contest;
