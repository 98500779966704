import * as React from "react";
import Api from "src/api";
import ContestAnnounce from "src/components/ContestAnnounce";
import SectionHeader from "src/components/SectionHeader/SectionHeader";
import Seo from "src/components/Seo";
import { Contest as ContestType, PageProps } from "src/types";
import Contest from "./Contest";
import classes from "./Contest.module.css";

interface Props extends PageProps {
  data?: { [x: string]: ContestType };
}
interface State {
  contest?: ContestType;
  announces: ContestType[];
}

class ContestContainer extends React.Component<Props, State> {
  public state: State = {
    contest: null,
    announces: [],
  };

  public async componentDidMount() {
    try {
      await this.getContest();
      await this.getContests();
    } catch (error) {
      // tslint:disable-next-line: no-unsafe-any
      console.error(error && error.message);
    }
  }

  public async componentDidUpdate(prevProps: Props) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      await this.getContest();
      window.scroll({
        top: 0,
      });
    }
  }

  public render() {
    const cntTrName = this.props.location.pathname.replace(/\/contestId\//, "");
    if (!cntTrName) return null;
    const staticData: ContestType = this.props.data[cntTrName];
    const cn: ContestType = this.state.contest || staticData;

    if (!cn) return null;
    return (
      <>
        <Seo
          pageTitle={cn.name}
          pageDescription={(cn.description || "").replace(/<(.|\n)*?>/g, "")}
        />
        <Contest {...cn} />
        {this.state?.announces?.length ? (
          <div
            style={{
              marginTop: "48px",
            }}
          >
            <SectionHeader header="Рекомендованные конкурсы" />
            <div className={classes.additionalContests}>
              {this.state.announces.map((curr) => (
                <ContestAnnounce key={curr._id} {...curr} onlyDate />
              ))}
            </div>
          </div>
        ) : null}
      </>
    );
  }

  private getContest = async () => {
    const res = await Api.getContest(this.props["*"]);
    this.setState({ contest: res.data });
  };

  private getContests = async () => {
    const res = await Api.getContestsAnnounces({}, { limit: 4 });
    this.setState({
      announces: res.data.data,
    });
  };
}

export default ContestContainer;
