import * as React from "react";
import { replacePostfix } from "src/components/DateFormater";
import Typography from "src/components/Typography";

interface Props {
  dateStart: string | Date;
  dateFinish: string | Date;
  isRange: boolean;
}

export const RenderDate: React.SFC<Props> = (props) => {
  if (!props.isRange) {
    return (
      <dl>
        <dt>
          <Typography type="smallerText">Дата проведения:</Typography>
        </dt>
        <dd>
          <Typography
            style={{
              textTransform: "capitalize",
            }}
            type="boldText"
          >
            {replacePostfix(
              new Date(props.dateFinish).toLocaleDateString("ru", {
                month: "long",
                year: "numeric",
              })
            )}
          </Typography>
        </dd>
      </dl>
    );
  }

  return (
    <>
      <dl>
        <dt>
          <Typography type="smallerText">Дата начала подачи заявки:</Typography>{" "}
        </dt>
        <dd>
          <Typography type="boldText">
            {replacePostfix(
              new Date(props.dateStart).toLocaleDateString("ru", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })
            )}
          </Typography>
        </dd>
      </dl>
      <dl>
        <dt>
          <Typography type="smallerText">
            Дата окончания подачи заявки:
          </Typography>{" "}
        </dt>
        <dd>
          <Typography type="boldText">
            {replacePostfix(
              new Date(props.dateFinish).toLocaleDateString("ru", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })
            )}
          </Typography>
        </dd>
      </dl>
    </>
  );
};
